<template>
  <v-flex class="pa-3" :style="`background: ${$theme.background};`" xs12>
    <v-simple-table
      v-if="$vuetify.breakpoint.smAndUp"
      style="border: 1px solid #E5E5E5"
      dense
    >
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <td class="font-weight-bold"> </td> -->
            <td class="font-weight-bold">#ID</td>
            <td class="font-weight-bold">NOME</td>
            <td class="font-weight-bold">CPF</td>
            <td class="font-weight-bold">EMAIL</td>
            <td class="font-weight-bold">TELEFONE</td>
            <td class="font-weight-bold"></td>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="
              `cursor: pointer; background: ${i % 2 === 0 ? '#f2f2f2' : '#fff'}`
            "
            v-for="(item, i) in get_clientes.docs"
            :key="item.id"
          >
            <!-- <td style="width: 10px"> <v-checkbox :color="$theme.primary" @click="setMarcado($event, item)" dense hide-details></v-checkbox> </td> -->
            <td
              style="width: 10px"
              @click="abre_modal_view_cliente(item)"
              class="font-weight-bold"
            >
              {{ item.id_cliente }}
            </td>
            <td @click="abre_modal_view_cliente(item)">{{ item.nome }}</td>
            <td @click="abre_modal_view_cliente(item)">{{ item.cpf }}</td>
            <td @click="abre_modal_view_cliente(item)">{{ item.email }}</td>
            <td @click="abre_modal_view_cliente(item)">{{ item.telefone }}</td>
            <td style="width: 10px">
              <v-btn
                @click="
                  createConfirmAction({
                    message: 'Deseja remover este cliente?',
                    icon: 'mdi-warning',
                    action: 'excluir_cliente',
                    action_value: item
                  })
                "
                icon
                ><v-icon> mdi-delete </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-list v-else class="pa-0 ma-0" color="transparent">
      <template v-for="(item, index) in get_clientes.docs">
        <v-list-item
          @click="abre_modal_view_cliente(item)"
          style="border-radius: 6px; margin-bottom: 6px; border: 1px solid orange;"
          :key="item._id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.nome }}</v-list-item-title>
            <v-list-item-subtitle> CPF: {{ item.cpf }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider :key="index"></v-divider> -->
      </template>
    </v-list>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Template from "../../shared/components/Template.vue";
export default {
  components: { Template },
  computed: {
    ...mapGetters(["get_clientes"])
  },
  methods: {
    ...mapActions(["abre_modal_view_cliente", "createConfirmAction"]),
    setMarcado(event, item) {
      console.log("event", event);
      // this.add_cliente_marcado(item)
    }
  }
};
</script>
